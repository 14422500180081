<template>
  <div class="bigEnterpriseManage">
    <div class="facilityBox">
      <input
        type="file"
        style="display: none"
        @change="handleUploadList"
        ref="uploadList"
      />

      <el-form
        class="searchForm"
        :model="searchForm"
        ref="searchForm"
        label-width="150px"
      >
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item
            label="企业名称："
            prop="enterpriseFullName"
            label-width="110px"
          >
            <el-input
              v-model="searchForm.enterpriseFullName"
              placeholder="请输入企业名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="社会统一信用代码："
            prop="enterpriseCreditCode"
          >
            <el-input
              v-model="searchForm.enterpriseCreditCode"
              placeholder="请输入社会统一信用代码"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="法人姓名："
            prop="legalPersonIName"
            label-width="110px"
          >
            <el-input
              v-model="searchForm.legalPersonIName"
              placeholder="请输入法人姓名"
              label-width="110px"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="服务商名称："
            prop="supplerName"
            v-if="roleType == 'platform'"
            label-width="110px"
          >
            <el-input
              v-model="searchForm.supplerName"
              placeholder="请输入服务商名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item label-width="10px">
            <div style="white-space: nowrap">
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                @click="search"
                icon="el-icon-search"
              >搜索</el-button>
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                icon="el-icon-delete"
                @click="resetForm"
              >清空</el-button>
              <el-button
                type="primary"
                size="medium"
                @click="toGetTemplate"
                icon="el-icon-download"
                v-if="roleType != 'platform'"
              >
                下载模板
              </el-button>
              <el-button
                type="primary"
                size="medium"
                @click="$refs.uploadList.click()"
                icon="el-icon-upload2"
                v-if="roleType != 'platform'"
              >
                导入企业表格
              </el-button>
              <el-button
                type="danger"
                size="medium"
                @click="delItem"
                icon="el-icon-delete"
                v-if="roleType != 'platform'"
                :disabled="!multipleSelection.length"
              >
                勾选删除企业
              </el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>

      <ux-grid
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        style="width: 100%"
        height="600"
        @select="handleSelectionChange"
        @select-all="handleSelectionChange"
        show-overflow
        :border="false"
        :row-style="{height:'55px'}"
        ref="uxGridRef"
      >
        <ux-table-column
          type="checkbox"
          align="center"
          width="50"
          fixed="left"
          v-if="roleType != 'platform'"
        ></ux-table-column>
        <ux-table-column
          type="index"
          align="center"
          title="序号"
          width="50"
          fixed="left"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="SupplerName"
          title="服务商名称"
          width="200"
          fixed="left"
          show-overflow
          v-if="roleType == 'platform'"
        ></ux-table-column>
        <ux-table-column
          v-for="(it,idx) in tableList"
          :key="idx"
          align="center"
          :field="it.key"
          :title="it.title"
          :width="it.width"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          title="操作"
          width="180"
          fixed="right"
          show-overflow
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="toEditEnterprise(scope.row)"
              v-if="roleType != 'platform'"
            >编辑</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="$refs.enterpriseDetail.watchDetail(scope.row)"
            >详情</el-button>
          </template>
        </ux-table-column>
      </ux-grid>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        :page-sizes="[10, 50, 100, 500, 1000]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>

    <!-- 查看详情 -->
    <enterpriseDetail ref="enterpriseDetail"></enterpriseDetail>
  </div>
</template>

<script>
import { tableList, detailImgList } from './data.js'
import { ImportEnterprise, EnterpriseList, getTemplate, EnterpriseDel } from '@/api/bigCustomerManage/index.js'
import enterpriseDetail from './enterpriseDetail.vue'

export default {
  props: {
    roleType: { // 角色类型
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      // 搜索表单
      searchForm: {},
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData: [], //表格数据
      multipleSelection: [],
      tableList,
      detailImgList,
      detailDialog: false,
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },


    //上传
    handleUploadList(e) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let formData = new FormData()
      formData.append('file', e.srcElement.files[0] || e.target.files[0])
      ImportEnterprise(formData)
        .then((res) => {
          this.$message.success("导入成功")
          this.getPageList()
        })
        .finally(() => {
          this.$refs.uploadList.value = null
          loading.close()
        })
    },

    //搜索
    search() {
      this.pagination.page = 1;
      this.loading = true;
      this.getPageList();
    },
    //清空
    resetForm() {
      this.loading = true;
      this.searchForm = {};
      this.pagination.page = 1;
      this.getPageList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getPageList();
    },
    sizeChange(e) {
      this.loading = true;
      this.pagination.pagesize = e;
      this.getPageList();
    },
    //获取列表
    getPageList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        isPlat: this.roleType == 'platform' ? true : false,
        ...this.searchForm
      };
      this.loading = true;
      EnterpriseList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 编辑
    toEditEnterprise(item) {
      this.$router.push({
        path: "/bigCustomerManage/bigEnterpriseManage/editEnterprise",
        query: {
          pagination: { ...this.pagination },
          searchForm: { ...this.searchForm },
          userId: item.UserID,
          enterpriseCreditCode: item.EnterpriseCreditCode,
        },
      });
    },

    // 下载模板
    async toGetTemplate() {
      try {
        this.loading = true
        let { data = '' } = await getTemplate({
          id: 4
        })

        if (data) {
          const el = document.createElement("a");
          el.style.display = "none";
          el.setAttribute("target", "_blank");
          el.setAttribute("download", "");
          el.href = data;
          document.body.appendChild(el);
          el.click();
          document.body.removeChild(el);
        } else {
          this.$message.error('模板异常，请联系管理员')
        }
      } finally {
        this.loading = false
      }
    },

    // 删除
    delItem() {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.toDelFn(this.multipleSelection)
        })
        .catch(() => { });
    },

    toDelFn(arr) {
      if (!arr.length) return;

      this.loading = true
      let len = arr.length; // 请求总数
      let sendCount = 0; // 已发送的请求数量
      let errorCount = 0; // 错误请求
      const _this = this;

      return new Promise((resolve, reject) => {
        next();

        function next() {
          let current = sendCount++; // 当前发送的请求数量，后加一 保存当前请求url的位置
          if (arr[current]) {
            EnterpriseDel({
              userID: arr[current].UserID,
              enterpriseCreditCode: arr[current].EnterpriseCreditCode,
            }).then(() => {
            }).catch(() => {
              errorCount = errorCount + 1;
            }).finally(() => {
              if (current < len - 1) { // 如果请求没有发送完，继续发送请求
                next();
              } else if (current == len - 1) {
                _this.loading = false
                _this.search();
                _this.multipleSelection = []
                _this.$refs.uxGridRef.clearSelection()

                if (errorCount) {
                  _this.$message.warning(`存在${errorCount}条，删除异常`)
                } else {
                  _this.$message.success("删除成功");
                }
                resolve();
                return;
              }
            })
          }
        }
      })
    },
  },
  created() {
    if (this.$route.query.pagination) {
      let { pagination, searchForm } = this.$route.query
      if (searchForm) this.searchForm = searchForm
      if (pagination) this.pagination = pagination
    }

    this.getPageList();
  },
  components: {
    enterpriseDetail
  }
}
</script>
